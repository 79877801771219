@use 'styles/breakpoints' as *;

.buttons {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include desktop {
    flex-direction: row;
    gap: 2.5rem;
  }
}

.underlineButton {
  align-self: center;
}
