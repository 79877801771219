@use 'styles/breakpoints' as *;

.sectionContainer {
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--menu-nav-height) + 29rem);

    @include desktop {
      height: calc(var(--menu-nav-height) + 49rem);
    }
    clip-path: polygon(
      0% 0%,
      0% 100%,
      100% calc(100% - var(--slope-height)),
      100% 0%
    );
    background-color: var(--bg-color);
    z-index: 0;
  }
}

.hero {
  padding-top: calc(var(--menu-nav-height) + 3.5rem);
  padding-bottom: 3rem;

  @include desktop {
    padding-bottom: 12.5rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include desktop {
    display: grid;
    grid-template:
      'content image' 45rem
      'author image' 0.1fr /
      42.5rem 1fr;

    &.hasNoAuthor {
      grid-template:
        'content image' 45rem
        '. image' 2.5rem /
        42.5rem 1fr;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  z-index: 0;

  @include desktop {
    gap: 1.25rem;
    justify-content: center;
    grid-area: content;
  }
}

.pills {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.author {
  padding: 1rem 0;
  align-self: center;

  @include desktop {
    align-self: unset;
    grid-area: author;
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  z-index: 0;

  @include desktop {
    grid-area: image;
  }
}

.image {
  @include desktop {
    max-width: fit-content;
    max-height: fit-content;
    height: 100%;
    width: auto;
  }
}
