@mixin defaultButtonStyle {
  appearance: none;
  border: none;
  background: none;
  z-index: 1;
  max-width: 100%;
  -webkit-tap-highlight-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  cursor: pointer;
  font-size: 1em;
  padding: 0;
}

@mixin disabled {
  opacity: 0.5;
  pointer-events: none;
}

@mixin variants {
  &.primary {
    --bg-color: var(--clr-primary);
    --text-color: var(--clr-white);
    --hover-text-color: var(--clr-white);

    &.inverted {
      --text-color: var(--clr-dark-gray);
      --hover-text-color: var(--clr-dark-gray);
    }

    &.ghost {
      --text-color: var(--clr-primary);
    }
  }

  &.secondary {
    --bg-color: var(--clr-dark-gray);
    --text-color: var(--clr-white);
    --hover-text-color: var(--clr-white);

    &.ghost {
      --text-color: var(--clr-dark-gray);
    }

    &.inverted {
      --bg-color: var(--clr-white);
      --text-color: var(--clr-dark-gray);
      --hover-text-color: var(--clr-dark-gray);

      &.ghost {
        --text-color: var(--clr-white);
      }
    }
  }

  &.tertiary {
    --bg-color: transparent;
    --text-color: var(--clr-dark-gray);
    --border-color: var(--clr-dark-gray-muted);
    --hover-text-color: var(--clr-dark-gray);

    &.inverted {
      --text-color: var(--clr-white);
      --border-color: var(--clr-white);
      --hover-text-color: var(--clr-white);
    }
  }
}
