@use 'styles/breakpoints' as *;

.hero {
  padding-top: calc(var(--menu-nav-height) + 2.25rem);
  padding-bottom: 7rem;
  --height: 10rem;

  @include desktop {
    padding-top: calc(var(--menu-nav-height) + 6rem);
    padding-bottom: 15rem;
    --height: 17.5rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--menu-nav-height) + var(--height));
    clip-path: polygon(
      0% 0%,
      0% 100%,
      100% calc(100% - var(--slope-height)),
      100% 0%
    );
    background-color: var(--clr-light-gray);
  }
}

.hasMultiContentRow {
  padding-bottom: 3.5rem;

  @include desktop {
    padding-bottom: 7rem;
  }
}

.compact {
  padding-bottom: 3rem;

  @include desktop {
    padding-bottom: 5rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  z-index: 1;
  position: relative;

  @include desktop {
    gap: 1.75rem;
  }
}

.pills {
  display: flex;
  gap: 0.62rem;

  @include desktop {
    gap: 0.75rem;
  }
}

.lead {
  padding: 1.25rem 0;
}

.image {
  width: 100%;
  height: auto;
  padding-top: 2.75rem;

  @include desktop {
    padding-top: 3.25rem;
  }
}
